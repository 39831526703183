enableSortable();

$body.on('click', ".invoice-clickable-row", function (e) {
    if (e.target.className != 'download-checkbox') {
        window.document.location = baseURL + 'admin/invoice/' + $(this).parents('tr[data-invoice]').data('invoice');
    }
});

$('.add-line').on('click', function () {
    $.post(baseURL + 'get-new-line', {
        '_token': $('meta[name="csrf-token"]').attr('content'),
        'invoice_id': $('[data-invoice-id]').data('invoice-id'),
        'type_line': $(this).data("line-type")
    }).success(function (response) {
        $('.sortable').append(response);
        $('.sortable-element').last().find('.edit-button').click();
        popup('alert-success', 'Mise à jour réussie');
    });
});

$body.on('click', '.delete-button', function () {
    var confirm_response = confirm('Voulez-vous vraiment supprimer la ligne ?');
    if (confirm_response) {
        $.post(baseURL + 'delete-line', {
            '_token': $('meta[name="csrf-token"]').attr('content'),
            'line_id': $(this).parents('[data-id-line]').data('id-line'),
        });
        $(this).parents('[data-id-line]').remove();
        popup('alert-success', 'Mise à jour réussie');
    }
});
$body.on('keyup', 'textarea.js-invoice-description', function () {
    textAreaAdjust(this);
});

$body.on('click', '.edit-button', function () {
    var $this = $(this);

    if ($this.hasClass('btn-info')) {
        // passe en mode editable
        disableSortable('.sortable');
        startInvoiceEdit($this);
    } else if ($this.hasClass('invoice-close-line')) {
        // annulation de la modification
        $.post(baseURL + 'get-line', {
            '_token': $('meta[name="csrf-token"]').attr('content'),
            'line_id': $(this).parents('[data-id-line]').data('id-line'),
            'invoice_id': $('[data-invoice-id]').data('invoice-id')
        }).success(function (response) {
            $this.parents("[data-id-line]").replaceWith($(response));
            popup('alert-info', 'Opération annulée');
            if ($('.invoice-close-line').length == 0) {
                $('.edit-all-line').removeClass('btn-success glyphicon-check').addClass('btn-primary glyphicon-edit');
            }
        });
    } else {
        // sauvegarde
        enableSortable('.sortable');

        var str_desc = $this.parents('[data-id-line]').find('.js-invoice-description').val();
        $.post(baseURL + 'save-invoice-line', {
            '_token': $('meta[name="csrf-token"]').attr('content'),
            'line_id': $(this).parents('[data-id-line]').data('id-line'),
            'invoice_id': $(this).parents('[data-invoice-id]').data('invoice-id'),
            'title': $this.parents('[data-id-line]').find('.js-invoice-title').text(),
            'quantity': $this.parents('[data-id-line]').find('.js-invoice-quantity').text(),
            'price': $this.parents('[data-id-line]').find('.js-invoice-price').text(),
            'tva': $this.parents('[data-id-line]').find('.js-invoice-tva').text(),
            'percentage': $this.parents('[data-id-line]').find('.js-invoice-percentage').text(),
            'description': str_desc,
            'type_line': $this.parents('[data-line-type]').data('line-type'),
        }).success(function (response) {
            $this.parents("[data-id-line]").replaceWith($(response));
            popup('alert-success', 'Mise à jour réussie');
            refreshTotal($('[data-invoice-id]').data('invoice-id'));
        });
        closeInvoiceEdit($this);
    }
});

$body.on('click', '.add-advance', function () {
    var advance_array = [];
    $("input:checked").parents('[data-advance-id]').each(function (key, value) {
        advance_array.push($(value).data('advance-id'));
    });
    var invoice_id = $("table[data-invoice-id]").data("invoice-id");
    $.post(baseURL + 'link-advance', {
        '_token': $('meta[name="csrf-token"]').attr('content'),
        'invoice_id': invoice_id,
        'advance_array': advance_array
    }).success(function () {
        popup('alert-success', 'Mise à jour réussie');
        location.reload();
    });
});

$body.on('click', '.delete-advance', function () {
    var $this = $(this);
    var confirm_response = confirm('Voulez-vous vraiment supprimer cette avance de la facture ?');
    if (confirm_response) {
        $.post(baseURL + 'delete-invoice-advance', {
            '_token': $('meta[name="csrf-token"]').attr('content'),
            'advance_id': $this.parents('[data-advance-id]').data('advance-id')
        }).success(function () {
            popup('alert-success', 'Mise à jour réussie');
            location.reload();
        });
    }
});

$body.on('change', '.change-state', function () {
    var $this = $(this);
    $.post(baseURL + 'invoice-change-state', {
        '_token': $('meta[name="csrf-token"]').attr('content'),
        'invoice_id': $("table[data-invoice-id]").data("invoice-id"),
        'state_id': $this.val()
    }).success(function () {
        popup('alert-success', 'La facture est ' + $('.change-state option:selected').text());
        location.reload();
    });
});


$body.on('click', '.use-model', function () {
    $.post(baseURL + "use-model", {
        '_token': $('meta[name="csrf-token"]').attr('content'),
        'invoice_id': $('[data-invoice-id]').data('invoice-id'),
        'model_id': $(".model-choice").val()
    }).success(function () {
        location.reload();
    });
});

var timeoutUpdate;
$body.on('change keyup', '.js-invoice-search', function () {
    clearTimeout(timeoutUpdate);
    var $self = $(this);
    timeoutUpdate = setTimeout(function () {
        $body.css('cursor', 'progress');
        $('.js-invoice-search').prop('disabled', 'disabled');
        var param_object = {};
        if ($.cookie('begin_date') !== 'undefined') {
            param_object['begin_date'] = $.cookie('begin_date');
        }
        if ($.cookie('end_date') !== 'undefined') {
            param_object['end_date'] = $.cookie('end_date');
        }
        $('.js-invoice-search').each(function () {
            if (this.value.length > 0 && this.value !== "0") {
                param_object[$(this).data('name')] = this.value;
            }
        });
        param_object['_token'] = $('meta[name="csrf-token"]').attr('content');
        $.post(baseURL + 'invoice-search', param_object).success(function (response) {
            $('.research-result').html(response);
            $('[data-toggle="popover"]').popover();
            $('[data-color]').each(function () {
                $(this).css('color', '#' + $(this).data('color'));
            });

            $.post(baseURL + 'update-invoice-page-total', param_object).success(function (total_response) {
                $('.invoice-page-total').html(total_response);
            });

            $('.js-invoice-search').removeProp('disabled');
            $body.css('cursor', 'default');
            $self.focus();
            clearTimeout(timeoutUpdate);
        }).fail(function () {
            alert("erreur, désolé");
        });
    }, 1000);
});

$body.on('click', '.js-change-client', function () {
    var $this = $(this);
    if ($this.hasClass('glyphicon-edit')) {
        $this.removeClass('glyphicon-edit btn-info').addClass('glyphicon-remove btn-warning');
        $('.js-invoice-client').html($("<label for='#'>Saisir le nom du client</label><br><input type='text' class='form-control js-client-input'>"));
        $.post(baseURL + "get-clients", {
            '_token': $('meta[name="csrf-token"]').attr('content')
        }).success(function (response) {
            var resp = JSON.parse(response);
            var autocompleteData = [];
            for (var i in resp) {
                autocompleteData.push({
                    display: resp[i].name + " (" + resp[i].id + ", " + ((resp[i].isActive) ? "Actif" : "Inactif") + ")",
                    value: resp[i].id
                });
            }
            $('.js-client-input').typeahead({
                source: autocompleteData,
                displayText: function (item) {
                    return item.display;
                },
                updater: function (item) {
                    var confirm_response = confirm('Voulez-vous vraiment changer le client de cette facture ?');
                    if (!confirm_response) {
                        return false;
                    }
                    $.post(baseURL + "update-invoice-client", {
                        '_token': $('meta[name="csrf-token"]').attr('content'),
                        'invoice_id': $('[data-invoice-id]').data('invoice-id'),
                        'client_id': item.value
                    }).success(function (response) {
                        $('.js-invoice-client').html(response);
                        var splitted_url = document.location.href.split('/');
                        splitted_url.pop();
                        history.replaceState({
                            'invoiceId': $('[data-invoice-id]').data('invoice-id')
                        }, 'TITLE');
                        $this.removeClass('glyphicon-remove btn-warning').addClass('glyphicon-edit btn-info');
                        popup('alert-success', 'Mise à jour réussie');
                        location.reload();
                    });
                }
            });
        });
    } else if ($this.hasClass('glyphicon-remove')) {
        $this.removeClass('glyphicon-remove btn-warning').addClass('glyphicon-edit btn-info');
        $.post(baseURL + "update-invoice-client", {
            '_token': $('meta[name="csrf-token"]').attr('content'),
            'invoice_id': $('[data-invoice-id]').data('invoice-id'),
            'client_id': $('[data-client-id]').data('client-id')
        }).success(function (response) {
            $('.js-invoice-client').html(response);
            popup('alert-info', 'Opération annulée');
        });
    }
});

$body.on('dblclick', '.js-calendar', function () {
    var $this = $(this);
    var initial_date = $this.text();
    $this.replaceWith($('<input type="text" class="js-datepicker" data-date-format="dd/mm/yyyy" data-provide="datepicker">'));
    var $date_picker = $('.js-datepicker');
    $date_picker.datepicker('show');
    $date_picker.datepicker({
        format: 'dd/mm/yyyy',
        defaultDate: 'now',
        autoclose: true
    });
    $date_picker.val(initial_date);
    $date_picker.on('changeDate', function () {
        var $this = $(this);
        $this.datepicker('hide');
        $.post(baseURL + 'update-invoice-date', {
            '_token': $('meta[name="csrf-token"]').attr('content'),
            'invoice_id': $('[data-invoice-id]').data('invoice-id'),
            'new_date': $this.val()
        }).success(function (response) {
            $this.remove();
            $('.js-append-date').append($("<h4 class='js-calendar'><span class='glyphicon glyphicon-calendar'>&nbsp;</span>" + response + "</h4>"));
            popup('alert-success', 'Mise à jour réussie');
        }).error(function (response) {
            $this.remove();
            $('.js-append-date').append($("<h4 class='js-calendar'><span class='glyphicon glyphicon-calendar'>&nbsp;</span>" + response.responseText + "</h4>"));
            popup('alert-danger', "La date saisie n'est pas correcte");
        });
    });
});

$body.on('click', '.edit-all-line', function () {
    var $this = $(this);
    if ($this.hasClass('glyphicon-edit')) {
        disableSortable('.sortable');
        $this.removeClass('glyphicon-edit').addClass('glyphicon-check');
        $this.parent().find('.js-append-btn').append('<button class="btn btn-warning btn-sm glyphicon glyphicon-remove close-all-line"></button>');
        $('.edit-button').click();
    } else if ($this.hasClass('glyphicon-check')) {
        $this.removeClass('glyphicon-check').addClass('glyphicon-edit').removeClass('btn-success').addClass('btn-primary');
        $this.parent().find('.js-append-btn').html('');
        var line_array = {
            '_token': $('meta[name="csrf-token"]').attr('content')
        };
        $('.sortable-element').each(function () {
            var $current = $(this);
            if ($current.data('line-type') == 'title_invoiceline') {
                line_array[$current.data('id-line')] = {
                    'title': $current.find('.js-invoice-title').text()
                };
            } else if ($current.data('line-type') == 'classic_invoiceline') {
                line_array[$current.data('id-line')] = {
                    'title': $current.find('.js-invoice-title').text(),
                    'quantity': $current.find('.js-invoice-quantity').text(),
                    'price': $current.find('.js-invoice-price').text(),
                    'tva': $current.find('.js-invoice-tva').text(),
                    'percentage': $current.find('.js-invoice-percentage').text(),
                    'description': $current.find('.js-invoice-description').val()
                };
            }
        });
        $.post(baseURL + 'update-all-invoice-lines', line_array).success(function () {
            location.reload();
        });
    }
});

$body.on('click', '.close-all-line', function () {
    location.reload();
});

$body.on('click', '.delete-all-line', function () {
    var confirm_response = confirm('Voulez-vous vraiment supprimer toutes les lignes de la facture ?');
    if (confirm_response) {
        $.post(baseURL + 'delete-all-invoice-lines', {
            '_token': $('meta[name="csrf-token"]').attr('content'),
            'invoice_id': $('[data-invoice-id]').data('invoice-id')
        }).success(function () {
            location.reload();
        });
    }
});

$body.on('click', '.send-invoice', function (event) {
    if ($(this).data('people') == 'client') {
        event.preventDefault();
        var confirm_response = confirm('Voulez-vous vraiment envoyer cette facture à ' + $(this).data('client-email') + ' ?');
        if (!confirm_response) {
            return false;
        }
        $('.mail-address').text($(this).data('client-email')).css('font-size', '18px');
        $('#send-client-mail-modal').modal('show');
        var invoice_id = $(this).data('send-invoice-id');
        $body.on('click', '.send-mail-to-client', function () {
            $.post(baseURL + 'send-to-the-client', {
                '_token': $('meta[name="csrf-token"]').attr('content'),
                invoice_id: invoice_id,
                message: $('.client-mail-content').val()
            });
        });
    } else {
        var confirm_response = confirm('Voulez-vous vraiment envoyer cette facture au ' + $(this).data('people') + ' ?');
        if (!confirm_response) {
            return false;
        }
    }
});

$body.on('click', '.create-invoice', function () {
    $(this).replaceWith($("<button style='float: right;' class='btn btn-warning glyphicon glyphicon-remove close-client-input'></button><input style='width: 250px; float: right; margin-right: 20px;' type='text' class='form-control create-invoice-client-input' placeholder='Saisir le nom du client'>"));
    $.post(baseURL + "get-clients", {
        '_token': $('meta[name="csrf-token"]').attr('content')
    }).success(function (response) {
        var resp = JSON.parse(response);
        var autocompleteData = [];
        for (var i in resp) {
            autocompleteData.push({
                display: resp[i].name + " (" + resp[i].id + ", " + ((resp[i].isActive) ? "Actif" : "Inactif") + ")",
                value: resp[i].id
            });
        }
        $('.create-invoice-client-input').typeahead({
            source: autocompleteData,
            displayText: function (item) {
                return item.display;
            },
            updater: function (item) {
                var confirm_response = confirm('Voulez-vous vraiment créer une nouvelle facture ?');
                if (!confirm_response) {
                    return false;
                }
                $('.client-id-hidden').val(item.value);
                $('.create-invoice-form').submit();
            }
        });
    });
});

$body.on('click', '.close-client-input', function () {
    $(this).parents('.create-invoice-button').html($('<button class="btn btn-primary pull-right create-invoice"><span class="glyphicon glyphicon-list-alt"></span><br>Nouvelle facture</button>'));
});

$(window).on('beforeunload', function () {
    if (downloading || $('.edit-all-line').is('.glyphicon-check, .btn-success')) {
        return true;
    }
});

var shouldStop = false;
var intervalFunction = null;
var ajaxRequest = null;
var downloading = false;
$body.on('click', '.download-invoices', function () {
    var $this = $(this);
    downloading = true;
    $this.data('action', 'download');
    clearInterval(intervalFunction);
    if (ajaxRequest !== null) {
        ajaxRequest.abort();
    }

    var invoice_nb = $('.download-checkbox:checked').length;

    if (invoice_nb == 0) {
        popup('alert-danger', "Aucune facture n'a été sélectionnées !");
        return false
    }

    if ($this.find('.glyphicon').hasClass('glyphicon-download')) {
        var confirm_response = confirm("Voulez-vous vraiment télécharger les " + invoice_nb + " factures sélectionnées sur cette page ?");

        if (!confirm_response) {
            popup('alert-warning', "Opération annulée");
            downloading = false;
            return false;
        }

        $this.find('.glyphicon').removeClass('glyphicon-download').addClass('glyphicon-remove');
    } else {
        $this.find('.glyphicon').removeClass('glyphicon-remove').addClass('glyphicon-download');
        $this.data('action', 'stop-download');
    }

    var array_invoice_id = [];
    $('.download-checkbox:checked').each(function () {
        array_invoice_id.push($(this).parents('tr[data-invoice]').data('invoice'));
    });

    $.post(baseURL + 'get-download-token', {
        '_token': $('meta[name="csrf-token"]').attr('content'),
    }).success(function (token) {
        $.post(baseURL + 'start-download', {
            '_token': $('meta[name="csrf-token"]').attr('content'),
            'download_token': token,
            'array_invoice_id': array_invoice_id
        }).error(function () {
            ajaxRequest.abort();
            clearInterval(intervalFunction);
            $('.js-counter').html("Télécharger les factures");
            downloading = false;
            popup('alert-danger', "Une erreur est survenue lors du téléchargement des factures");
        });
        intervalFunction = setInterval(function () {
            ajaxRequest = $.post(baseURL + 'get-download-progress', {
                '_token': $('meta[name="csrf-token"]').attr('content'),
                'download_token': token
            }, null, "JSON").success(function (json_response) {
                if ($this.data('action') == 'stop-download') {
                    ajaxRequest.abort();
                    clearInterval(intervalFunction);
                    $('.js-counter').html("Télécharger les factures");
                    popup('alert-warning', "Téléchargement annulé");
                    $this.find('.glyphicon').removeClass('glyphicon-remove').addClass('glyphicon-download');
                    downloading = false;
                } else {
                    if (json_response.status == "generate-pdf") {
                        $('.js-counter').html("<b class='text-success'>" + json_response.progress + '/' + json_response.total + "</b>");
                    } else if (json_response.status == "finished") {
                        clearInterval(intervalFunction);
                        downloading = false;
                        window.location = baseURL + 'admin/download-zip/' + token;
                        setTimeout(function () {
                            downloading = false;
                            $('.js-counter').html("Télécharger les factures");
                            $this.find('.glyphicon').removeClass('glyphicon-remove').addClass('glyphicon-download');
                            if (json_response.errors.length > 0) {
                                popup('alert-danger', "Erreur " + json_response.progress + '/' + json_response.total + ' factures téléchargées');
                            } else {
                                popup('alert-success', "Téléchargement terminé");
                            }
                        }, 100);
                    } else if (json_response.status == "generate-zip") {
                        $('.js-counter').html("<b class='text-success'>Génération du zip</b>");
                    } else {
                        clearInterval(intervalFunction);
                        popup('alert-danger', "Téléchargement interrompu");
                        downloading = false;
                    }
                }
            });
        }, 400);
    }).error(function () {
        ajaxRequest.abort();
        clearInterval(intervalFunction);
        $('.js-counter').html("Télécharger les factures");
        downloading = false;
        popup('alert-danger', "Une erreur est survenue lors du téléchargement des factures");
    });
});

$body.on('click', '.see-client-info', function () {
    window.location = baseURL + 'admin/client/' + $('.js-invoice-client').data('client-id');
});

$body.on('click', '.change-invoice-phrasing', function () {
    var $this = $(this);
    if ($this.hasClass('btn-info')) {
        $this.removeClass('btn-info glyphicon-edit').addClass('btn-success glyphicon-check');
        $('.invoice-phrasing').attr('contenteditable', 'true');
    } else if ($this.hasClass('btn-success')) {
        $this.removeClass('btn-success glyphicon-check').addClass('btn-info glyphicon-edit');
        var phrasing_val = $('.invoice-phrasing').text();
        $.post(baseURL + 'update-invoice-phrasing', {
            '_token': $('meta[name="csrf-token"]').attr('content'),
            invoice_id: $("table[data-invoice-id]").data('invoice-id'),
            phrasing: phrasing_val
        }).success(function () {
            $('.invoice-phrasing').attr('contenteditable', 'false');
        });
    }
});

$body.on('click', '.select-all-checkbox', function () {
    var $this = $(this);
    if ($this.is(':checked')) {
        $('.download-checkbox').prop('checked', true);
    } else if (!$this.is(':checked')) {
        $('.download-checkbox').prop('checked', false);
    }
});