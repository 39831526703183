$(window).on('scroll', function () {
    var scrollTop = $(window).scrollTop();
    var $navbar = $('.js-navbar');
    var navWidth = $navbar.width();
    if (scrollTop > 20) {
        $navbar.addClass('navbar-after-scroll');
        $body.css('padding-top', $('.navbar').height() * 2);
        $navbar.width(navWidth);
    } else if (scrollTop <= 30) {
        $navbar.removeClass('navbar-after-scroll');
        $body.css('padding-top', 0);
    }
});

function popup(message_type, message) {
    $('.custom-alert-message').addClass(message_type + ' show').find('.text').text(message);
    setTimeout(function () {
        $('.custom-alert-message').removeClass(message_type + ' show');
    }, 3000);
}

$('[data-toggle="popover"]').popover();

$('[data-color]').each(function () {
    $(this).css('color', '#' + $(this).data('color'));
});

$('.datepicker').datepicker({
    format: 'dd/mm/yyyy',
    autoclose: true
});

function nl2br(str, is_xhtml) {
    var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
    return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
}

$(".sortable").sortable({
    tolerance: 'pointer',
    handle: '.sortable-handle',
    placeholder: 'span2 well placeholder tile',
    forceHelperSize: true,
    update: function () {
        var data_object = $(this).sortable('toArray', {attribute: 'data-id-line'});
        $.post(baseURL + 'update-line-order', {
            '_token': $('meta[name="csrf-token"]').attr('content'),
            'data': data_object
        }).success(function () {
            popup('alert-success', 'Mise à jour réussie');
        });
    }
});

function enableSortable(sortClass) {
    $(sortClass).sortable("enable");
    $(sortClass).css({'cursor': 'move'});
}

function disableSortable(sortClass) {
    $(sortClass).sortable("disable");
    $('.sortable-element').css({'cursor': 'auto'});
}

function brtonl(str) {
    if (typeof(str) == "undefined") {
        return "";
    }
    return str.split("\n").join("").split("<br>").join("\n");
}

function textAreaAdjust(o) {
    o.style.height = "1px";
    o.style.height = (3 + o.scrollHeight) + "px";
}

function startInvoiceEdit($this) {
    $this.removeClass('btn-info glyphicon-edit').addClass('btn-success glyphicon-check');
    $this.parents('[data-id-line]').find('.js-editable').attr('contenteditable', 'true');
    var description = $this.parents('[data-id-line]').find('.js-invoice-description').html();
    $this.parents('[data-id-line]').find('.js-invoice-description')
        .replaceWith("<textarea class='invoice-description js-invoice-description'>" + brtonl(description) + "</textarea>");
    $this.parents('[data-id-line]').find('.js-append-btn').append('<button class="btn btn-warning btn-sm glyphicon glyphicon-remove edit-button invoice-close-line"></button>');
    $this.parents('[data-id-line]').find('.sort-icon').remove();

    $body.find("textarea.js-invoice-description").each(function () {
        textAreaAdjust(this);
    });

    $('.edit-all-line').removeClass('glyphicon-edit btn-primary').addClass('glyphicon-check btn-success');
}

function closeInvoiceEdit($this) { // TODO Si plus de bouton activé changer l'état du edit-all
    $this.removeClass('btn-success glyphicon-check').addClass('btn-info glyphicon-edit');
    $this.parents('[data-id-line]').find('.js-editable').attr('contenteditable', 'false');
    $this.parents('.js-append-btn').find('.cancel-btn').remove();
    $('.js-append-btn').html('');
    if ($('.invoice-close-line').length == 0) {
        $('.edit-all-line').removeClass('btn-success glyphicon-check').addClass('btn-primary glyphicon-edit');
    }
}

function refreshTotal(invoice_id) {
    $.post(baseURL + 'update-total', {
        '_token': $('meta[name="csrf-token"]').attr('content'),
        'invoice_id': invoice_id
    }).success(function (response) {
        $('.invoice-total').html(response);
    });
}

function startClientEdit($this) {
    $.post(baseURL + 'get-tva', {
        '_token': $('meta[name="csrf-token"]').attr('content')
    }).success(function (responses) {
        var select = $('<select class="form-control client-tva client-tva-edit"></select>');
        responses.forEach(function (k) {
            if ($('.client-tva').text() == k.rate) {
                select.append($('<option></option>').attr({
                    'value': k.id,
                    'selected': 'selected'
                }).text(k.phrasing));
            } else {
                select.append($('<option></option>').attr('value', k.id).text(k.phrasing));
            }
        });
        $('.client-tva').replaceWith(select);
    });
    $this.parents('[data-client-id]').data('editable', true);
    $this.removeClass('glyphicon-edit').addClass('glyphicon-floppy-disk');
    $this.parents('[data-client-id]').find('.js-client-edit').attr('contenteditable', 'true');
    $('.client-header').find('h4').css('color', 'red').attr('contenteditable', true);
}

function closeClientEdit($this) {
    $this.parents('[data-client-id]').data('editable', false);
    $this.removeClass('glyphicon-floppy-disk').addClass('glyphicon-edit');
    $this.parents('[data-client-id]').find('.js-client-edit').attr('contenteditable', 'false');
    $('.client-header').find('h4').css('color', 'white').attr('contenteditable', 'false');
    $('.client-tva').replaceWith($('<p>' + $('.client-tva').find(":selected").text() + '</p>'));
}

function startAdvanceEdit($this) {
    $this.removeClass('glyphicon-edit').addClass('glyphicon-floppy-disk');
    var value = $this.parents('[data-advance-id]').find(".advance-date").text();
    var $datepicker = $('<input class="datepicker advance-date form-control" data-date-format="mm/dd/yyyy" data-provide="datepicker">').attr('value', value);
    $this.parents('[data-advance-id]').find(".advance-date").replaceWith($datepicker);
    $this.parents('[data-advance-id]').find(".advance-amount").attr('contenteditable', true);
    $('.datepicker').datepicker({
        format: 'dd/mm/yyyy',
        autoclose: true
    });
}