if (modal_errors) {
    $('#myModal').modal('show');
}

$body.on('click', '.client-clickable-row', function () {
    window.document.location = baseURL + 'admin/client/' + $(this).data('company');
});

$body.on('click', '.client-edit', function () {
    var $this = $(this);
    if ($this.parents('[data-client-id]').data('editable') == false) {
        startClientEdit($this);
    } else if ($this.parents('[data-client-id]').data('editable') == true) {
        var client_object = {
            '_token': $('meta[name="csrf-token"]').attr('content'),
            'client_id': $this.parents('[data-client-id]').data('client-id'),
            'company': $('.client-company').text(),
            'type': $('.client-type').text(),
            'address': $('.client-address').text(),
            'zipcode': $('.client-zipcode').text(),
            'city': $('.client-city').text(),
            'country': $('.client-country').text(),
            'tva_id': $('.client-tva').find(":selected").val(),
            'phone': $('.client-phone').text(),
            'email': $('.client-email').text()
        };
        $.post(baseURL + 'update-client', client_object);
        closeClientEdit($this);
        popup('alert-success', 'Mise à jour réussie');
    }
});

$body.on('click', '.client-delete', function () {
    var confirm_response = confirm('Voulez-vous vraiment supprimer ce client ?');
    if (confirm_response) {
        $.post(baseURL + 'delete-client', {
            '_token': $('meta[name="csrf-token"]').attr('content'),
            'client_id': $(this).parents('[data-client-id]').data('client-id'),
        }).success(function () {
            $(location).attr('href', baseURL + '/admin/clients');
        });
    }
});

$body.on('click', '.advance-clickable', function () {
    var $this = $(this);
    if ($this.data('action') == 'insertion') {
        $.post(baseURL + 'create-advance', {
            '_token': $('meta[name="csrf-token"]').attr('content'),
            'client_id': $('[data-client-id]').data('client-id'),
        }).success(function (response) {
            $('.client-advance').html(response);
            $("[data-editable]").last().click();
            popup('alert-success', 'Mise à jour réussie');
        });
    } else if ($this.data('action') == 'edition') {
        if (!$this.data('editable')) {
            startAdvanceEdit($this);
            $this.data('editable', true);
        } else {
            $.post(baseURL + 'update-advance', {
                '_token': $('meta[name="csrf-token"]').attr('content'),
                'advance_id': $this.parents('[data-advance-id]').data('advance-id'),
                'created_at': $this.parents('[data-advance-id]').find(".advance-date").val(),
                'amount': $this.parents('[data-advance-id]').find(".advance-amount").text(),
                'client_id': $this.parents('[data-client-id]').data('client-id')
            }).success(function (response) {
                $('.client-advance').html(response);
                popup('alert-success', 'Mise à jour réussie');
            });
        }
    } else if ($this.data('action') == 'deletion') {
        var $this = $(this);
        var confirm_response = confirm('Voulez-vous vraiment supprimer cette avance ?');
        if (confirm_response) {
            $.post(baseURL + 'delete-advance', {
                '_token': $('meta[name="csrf-token"]').attr('content'),
                'advance_id': $this.parents('[data-advance-id]').data('advance-id'),
                'client_id': $this.parents('[data-client-id]').data('client-id')
            }).success(function (response) {
                $('.client-advance').html(response);
                popup('alert-success', 'Mise à jour réussie');
            });
        }
    }
});

$("#switch-state").bootstrapSwitch().on('switchChange.bootstrapSwitch', function (event, state) {
    $.post(baseURL + 'change-state', {
        '_token': $('meta[name="csrf-token"]').attr('content'),
        'client_id': $(this).parents('[data-client-id]').data('client-id')
    }).success(function () {
        popup('alert-success', 'Mise à jour réussie');
    });
});

$body.on('keyup', ".js-client-search", function () {
    var param_object = {};
    $('.js-client-search').each(function () {
        if (this.value.length > 0 && this.value !== "0") {
            param_object[$(this).data('name')] = this.value;
        }
    });
    param_object['_token'] = $('meta[name="csrf-token"]').attr('content');
    $.post(baseURL + 'client-search', param_object).success(function (response) {
        $('.research-result').html(response);
    });
});

$body.on('click', '.new-invoice', function () {
    var confirm_response = confirm('Voulez-vous vraiment créer une nouvelle facture ?');
    if (!confirm_response) {
        return false;
    }
});