$body.on('click', '.search-refresh', function () {
    $('.js-research-date').each(function () {
        if (this.value.length == 0) {
            $.cookie($(this).data('name'), '', {expires: -1, path: '/'});
        } else {
            $.cookie($(this).data('name'), this.value, {expires: 3, path: '/'});
        }
    });
    location.reload();
});

$body.on('click', '.search-clear-input', function () {
    $('.js-research-date').each(function () {
        if (this.value.length > 0) {
            $.cookie($(this).data('name'), '', {expires: -1, path: '/'});
        }
    });
    location.reload();
});

$body.on('click', '.js-research-current-month', function () {
    var current_date = new Date();
    var begin = new Date(current_date.getFullYear(), current_date.getMonth(), 1);
    var end = new Date(current_date.getFullYear(), current_date.getMonth() + 1, 0);
    $('.js-research-begin-date').val(begin.getDate() + '/' + (begin.getMonth() + 1) + '/' + begin.getFullYear());
    $('.js-research-end-date').val(end.getDate() + '/' + (end.getMonth() + 1) + '/' + end.getFullYear());
    $('.search-refresh').trigger('click');
});

$body.on('click', '.js-research-current-year', function () {
    var current_date = new Date();
    $('.js-research-begin-date').val("01/01/" + (current_date.getFullYear()));
    $('.js-research-end-date').val("31/12/" + (current_date.getFullYear()));
    $('.search-refresh').trigger('click');
});

$body.on('change', ".js-research-year", function () {
    var selected_item = $(this).val();
    $('.js-research-begin-date').val("01/01/" + selected_item);
    $('.js-research-end-date').val("31/12/" + selected_item);
    $('.search-refresh').trigger('click');
});

$body.on('click', '.js-research-year-passed', function () {
    var current_date = new Date();
    var previous_year = $(this).data('year');
    $('.js-research-begin-date').val("01/01/" + (current_date.getFullYear() - previous_year));
    $('.js-research-end-date').val("31/12/" + (current_date.getFullYear() - previous_year));
    $('.search-refresh').trigger('click');
});