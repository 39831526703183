$('.sortable-state').sortable({
    tolerance: 'pointer',
    placeholder: 'span2 well placeholder tile',
    forceHelperSize: true,
    update: function () {
        var data_object = $(this).sortable('toArray', {attribute: 'data-state-id'});
        $.post(baseURL + 'update-state-order', {
            '_token': $('meta[name="csrf-token"]').attr('content'),
            'data': data_object
        }).success(function () {
            popup('alert-success', 'Mise à jour réussie');
        });
    }
});

$body.on('click', '.update-state', function () {
    var $this = $(this);
    if ($this.hasClass('glyphicon-edit')) {
        disableSortable('.sortable-state');
        $this.removeClass('glyphicon-edit btn-info').addClass('glyphicon-check btn-success');
        $this.parents('[data-state-id]').find('.js-append-btn').append('<button class="btn btn-warning btn-sm glyphicon glyphicon-remove close-line"></button>');
        $this.parents('[data-state-id]').find('.state-edit').attr('contenteditable', true);
    } else if ($this.hasClass('glyphicon-check')) {
        enableSortable('.sortable-state');
        $this.removeClass('glyphicon-check btn-success').addClass('glyphicon-edit btn-info');
        $this.parents('[data-state-id]').find('.js-append-btn').html('');
        $this.parents('[data-state-id]').find('.state-edit').attr('contenteditable', false);
        $.post(baseURL + 'update-state', {
            '_token': $('meta[name="csrf-token"]').attr('content'),
            'state_id': $this.parents('[data-state-id]').data('state-id'),
            'phrasing': $this.parents('[data-state-id]').find('.state-phrasing').text(),
            'glyphicon': $this.parents('[data-state-id]').find('.state-glyphicon').text(),
            'color': $this.parents('[data-state-id]').find('.state-color').text().replace('#', '')
        }).success(function (response) {
            $this.parents("[data-state-id]").replaceWith($(response));
            popup('alert-success', 'Mise à jour réussie');
        });
    }
});

$body.on('click', '.close-line', function () {
    var $this = $(this);
    $.post(baseURL + 'get-state-line', {
        '_token': $('meta[name="csrf-token"]').attr('content'),
        'state_id': $this.parents('[data-state-id]').data('state-id')
    }).success(function (response) {
        $this.parents("[data-state-id]").replaceWith($(response));
        popup('alert-success', 'Mise à jour réussie');
    });
});

$body.on('click', '.delete-state', function () {
    var confirm_response = confirm('Voulez-vous vraiment supprimer tous les status ?');
    if (confirm_response) {
        $.post(baseURL + 'delete-state', {
            '_token': $('meta[name="csrf-token"]').attr('content'),
            'state_id': $(this).parents('[data-state-id]').data('state-id'),
        }).success(function () {
            popup('alert-success', 'Mise à jour réussie');
            location.reload();
        });
    }
});

$body.on('keyup', '.state-glyphicon', function () {
    if (this.value.length > 8) {
        var lastClass = $('.add-glyph').attr('class').split(' ').pop();
        if (lastClass != 'add-glyph') {
            $('.add-glyph').removeClass(lastClass);
        }
        $('.add-glyph').addClass(this.value);
    }
});

$body.on('keyup', '.state-color', function () {
    $('.display-color').css('background-color', this.value);
});

if (modal_errors) {
    $('#myModal').modal('show');
    popup('alert-danger', "Une erreur c'est produite lors de l'ajout");
}