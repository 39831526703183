var tva_id = null;

$body.on('click', '.add-tva', function () {
    $('.tva-phrasing').val("");
    $('.tva-rate').val("");
    tva_id = null;
});

$body.on('click', '.save-tva', function () {
    $.post(baseURL + 'add-tva', {
        '_token': $('meta[name="csrf-token"]').attr('content'),
        'id' : (tva_id) ? tva_id : null,
        'phrasing' : $('.tva-phrasing').val(),
        'rate' : $('.tva-rate').val()
    }).success(function () {
        popup('alert-success', 'Mise à jour réussie');
        location.reload();
    });
});

$body.on('click', '.update-tva', function () {
    $('.tva-phrasing').val($(this).find("[data-tva-phrasing]").data('tva-phrasing'));
    $('.tva-rate').val($(this).find("[data-tva-rate]").data('tva-rate'));
    tva_id = $(this).data('tva-id');
});

$body.on('click', '.delete-tva', function () {
    $.post(baseURL + 'delete-tva', {
        '_token': $('meta[name="csrf-token"]').attr('content'),
        'tva_id' : $(this).parents('[data-tva-id]').data('tva-id')
    }).success(function () {
        popup('alert-success', 'Mise à jour réussie');
        location.reload();
    });
});