if (modal_errors) {
    $('#user-modal').modal('show');
}

$body.on('click', '.js-add-user', function () {
    $('.js-user-id').val(0);
    $('.js-lastname-input').val('');
    $('.js-firstname-input').val('');
    $('.js-email-input').val('');
    $('.js-password-input').val('');
});

$body.on('click', '.save-user', function () {
    $('form.user-form').submit();
});

$body.on('click', '.update-user', function () {
    var $this = $(this);
    $('.js-update-user').val('update');
    $('.js-user-id').val($(this).parents('[data-user-id]').data('user-id'));
    $('.js-lastname-input').val($(this).parents('[data-user-id]').find('.js-user-lastname').text());
    $('.js-firstname-input').val($(this).parents('[data-user-id]').find('.js-user-firstname').text());
    $('.js-email-input').val($(this).parents('[data-user-id]').find('.js-user-email').text());
    $('.js-password-input').val('');
});

$body.on('click', '.remove-user', function () {
    var confirm_response = confirm('Voulez-vous vraiment supprimer cet utilisateur ?');
    if (!confirm_response) {
        return false;
    }
    var $this = $(this);
    $.post(baseURL + 'remove-user', {
        '_token': $('meta[name="csrf-token"]').attr('content'),
        user_id: $(this).parents('[data-user-id]').data('user-id')
    }).success(function () {
        $this.parents('.user-line').remove();
        popup('alert-success', 'Opération réussie');
    });
});